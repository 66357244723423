body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a {
  text-decoration: none;
}

.fullscreen-enabled {
  background: #fff;
}

.MuiTableCell-root {
  font-size: 2rem !important;
}

.MuiTableCell-head {
  font-weight: 600 !important;
  line-height: 2.1rem !important;
}

@media (max-width: 480px) {
  .picking2-container {
    font-size: 10px;
  }
}

.statusType {
  display: block;
  width: 30px;
  height: 20px;
  border-radius: 20px;
  margin-left: 10px;
  border: 1px solid black;
}

.collectionStatusCreated {
  color: "white";
  background-color: #445a3e;
}
.collectionStatusPending {
  color: "white";
  background-color: #000080;
}

.collectionStatusIssues {
  color: "white";
  background-color: #800080;
}

.collectionStatusCompleted {
  color: "white";
  background-color: #d89905;
}
